.ReactTable {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow-y: hidden;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.ReactTable .rt-thead .rt-tr,
.ReactTable .th-sticky-first,
.ReactTable .th-sticky-last {
  text-align: inherit;
  font-weight: bold;
  color: #939393 !important;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .th-sticky-first,
.ReactTable .th-sticky-last {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  line-height: normal;
  position: relative;
  border-right: 1px solid #f0f0f0;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.th-cursor-pointer,
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
  /*  border-right: 1px solid #eee; */
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.rt-resizable-header-content,
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ReactTable .rt-tbody.rt-tbody-mobile,
.ReactTable .rt-theader-mobile {
  min-width: 0 !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-block-size: 3em;
  font-size: 10pt;
  color: #333;
}
.ReactTable .rt-tr-mobile {
  flex-direction: column;
  padding: 15px;
}
.rt-tr-selected {
  background: rgba(0, 140, 255, 0.08);
}
.rt-tr-edited {
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2);
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th {
  padding: 0 10px;
}
.ReactTable .rt-td-mobile {
  width: inherit !important;
}

.ReactTable .td-sorted {
  background: rgba(0, 0, 0, 0.04);
}

.ReactTable .td-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 auto;
}
.ReactTable .rt-expander-mobile {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  right: 20px;
}
.ReactTable .rt-header-expander {
  display: block;
  margin: 0 auto;
  padding: 0;
  border: none;
  background: transparent;
}

.ReactTable .rt-header-expander:focus {
  outline: none;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid #aaa;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2);
}
.ReactTable .rt-noData {
  display: flex;
  justify-content: center;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* sticky first */

.ReactTable .th-sticky-first {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 45px;
  line-height: normal;
  overflow: visible;
  background-color: white;
  border-right: 1px solid #f6f6f6;
  box-shadow: 3px 0 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .td-sticky-first-selectable {
  left: 35px !important;
  z-index: 1;
}
.ReactTable .th-sticky-first.th-sort-asc {
  background-color: #f6f6f6;
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6),
    3px 0 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .th-sticky-first.th-sort-desc {
  background-color: #f6f6f6;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6),
    3px 0 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .th-sticky-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  display: flex;
  align-items: center;
  height: 45px;
  line-height: normal;
  background-color: white;
  border-right: none;
  box-shadow: 3px 0 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .td-sticky-first {
  display: flex;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  background-color: white;
  box-shadow: 3px 0px 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .td-sticky-first.td-sorted {
  background-color: #f6f6f6;
}
/* sticky last */

.ReactTable .th-sticky-last {
  background-color: white;
  position: absolute !important;
  z-index: 40;
  box-shadow: -3px 0 8px -3px rgba(0, 0, 0, 0.2) !important;
  border-right: none !important;
}
.ReactTable .td-sticky-last {
  display: flex;
  flex-wrap: nowrap;
  position: sticky;
  right: 0;
  background-color: white;
  box-shadow: -3px 0px 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .td-sticky-last-more {
  position: sticky;
  right: 0;
  background-color: white;
}
.ReactTable .td-sticky-last-more-shadow {
  box-shadow: -3px 0px 8px -3px rgba(0, 0, 0, 0.2);
}
.ReactTable .td-sticky-last-float-container {
  position: absolute;
  right: 40px;
  padding-right: 8px;
  background-color: white;
  box-shadow: -3px 0 8px -3px rgba(0, 0, 0, 0.2);
}

/* sticky hover row */

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .td-sticky-first,
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .td-sticky-last,
.ReactTable.-highlight
  .rt-tbody
  .rt-tr:not(.-padRow):hover
  .td-sticky-last-more {
  z-index: 1;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2) !important;
}

.ReactTable.-highlight
  .rt-tbody
  .rt-tr:not(.-padRow):hover
  .td-sticky-first-selectable {
  box-shadow: 3px 0 6px -3px rgba(0, 0, 0, 0.2) !important;
}

/* sticky selected row */

.ReactTable .rt-tr-selected .td-sticky-last,
.ReactTable .rt-tr-selected .td-sticky-last,
.ReactTable .rt-tr-selected .rt-td.rthfc-td-fixed,
.ReactTable .rt-tr-selected .td-sticky-first-selectable {
  background-color: #eef8ff !important;
}

/*------------ scroll event version: -se ------------*/

.rthfc.-se .-filters .rt-th.rthfc-th-fixed,
.rthfc.-se .rt-td.rthfc-td-fixed {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 3px 0 8px -3px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 960px) {
  .ReactTable .rt-expander {
    margin-left: auto;
    margin-right: 10px;
  }
}
